<template>
    <div>
        <b-container class="pt-5 pb-5">
            <ValidationObserver v-slot="{ invalid, validate }">
                <b-form @submit.prevent="validate().then(saveForm)" autocomplete="off">
                    <b-card class="mb-3">
                        <h1 class="fs-4 font-weight-bold mb-3">Profiel details</h1>
                        <b-row>
                            <b-col md="6">
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <div class="mb-3">
                                        <label class="required">Voornaam</label>
                                        <b-form-input v-model="user.firstname" />
                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                    </div>
                                </ValidationProvider>
                            </b-col>
                            <b-col md="6">
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <div class="mb-3">
                                        <label class="required">Achternaam</label>
                                        <b-form-input v-model="user.lastname" />
                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                    </div>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <ValidationProvider rules="required|phone" v-slot="{ errors }">
                            <div class="mb-3">
                                <label class="required">Telefoonnummer</label>
                                <b-form-phone v-model="user.phone" />
                                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                                <label class="required">Geboortedatum</label>
                                <b-form-input v-model="user.birthdate" type="date" max="9999-12-31" />
                                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <div class="mb-3">
                                <label class="required">Geslacht</label>
                                <b-form-select v-model="user.gender" :options="genderSelect" />
                                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                            </div>
                        </ValidationProvider>
                        <b-row>
                            <b-col sm="6" md="5">
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <div class="mb-3">
                                        <label class="required">Adres</label>
                                        <b-form-input v-model="user.address" />
                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                    </div>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="6" md="3">
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <div class="mb-3">
                                        <label class="required">Postcode</label>
                                        <b-form-input v-model="user.zipcode" />
                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                    </div>
                                </ValidationProvider>
                            </b-col>
                            <b-col sm="12" md="4">
                                <ValidationProvider rules="required" v-slot="{ errors }">
                                    <div class="mb-3">
                                        <label class="required">Woonplaats</label>
                                        <b-form-input v-model="user.city" />
                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                    </div>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-card>
                    <div class="mb-3 text-right">
                        <b-button type="submit" variant="primary" :disabled="invalid || isSubmited">
                            Profiel wijzigen
                            <b-spinner small v-if="isSubmited" class="align-middle" />
                        </b-button>
                    </div>
                </b-form>
            </ValidationObserver>

            <ValidationObserver v-slot="{ invalid, validate }">
                <b-form @submit.prevent="validate().then(saveForm)" autocomplete="off">
                    <b-card class="mb-3">
                        <h1 class="fs-4 font-weight-bold mb-3">E-mailadres wijzigen</h1>
                        <b-alert class="border" variant="info" show>
                            <font-awesome-icon icon="info-circle" class="mr-1"/>
                            Dit is ook het e-mailadres waarop je alle tickets ontvangt en waarmee je inlogt.
                        </b-alert>
                        <b-row>
                            <b-col>
                                <ValidationProvider vid="email" rules="required" v-slot="{ errors }">
                                    <div class="mb-3">
                                        <label class="required">E-mailadres</label>
                                        <b-form-input v-model="user.email" />
                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                    </div>
                                </ValidationProvider>
                            </b-col>
                            <b-col>
                                <ValidationProvider rules="required|confirmed:email" v-slot="{ errors }">
                                    <div class="mb-3">
                                        <label class="required">Herhaal E-mailadres</label>
                                        <b-form-input v-model="user.email_repeat" />
                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                    </div>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-card>
                    <div class="mb-3 text-right">
                        <b-button type="submit" variant="primary" :disabled="invalid || isSubmited">
                            E-mailadres wijzigen
                            <b-spinner small v-if="isSubmited" class="align-middle" />
                        </b-button>
                    </div>
                </b-form>
            </ValidationObserver>

            <ValidationObserver v-slot="{ invalid, validate }">
                <b-form @submit.prevent="validate().then(saveForm)" autocomplete="off">
                    <b-card class="mb-3">
                        <h1 class="fs-4 font-weight-bold mb-3">Wachtwoord wijzigen</h1>
                        <b-row>
                            <b-col>
                                <ValidationProvider vid="password" rules="required" v-slot="{ errors }">
                                    <div class="mb-3">
                                        <label class="required">Wachtwoord</label>
                                        <b-form-input v-model="user.password" type="password" autocomplete="new-password" />
                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                    </div>
                                </ValidationProvider>
                            </b-col>
                            <b-col>
                                <ValidationProvider rules="required|confirmed:password" v-slot="{ errors }">
                                    <div class="mb-3">
                                        <label class="required">Herhaal wachtwoord</label>
                                        <b-form-input v-model="user.password_repeat" type="password" autocomplete="new-password" />
                                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                                    </div>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                    </b-card>
                    <div class="mb-3 text-right">
                        <b-button type="submit" variant="primary" :disabled="invalid || isSubmited">
                            Wachtwoord wijzigen
                            <b-spinner small v-if="isSubmited" class="align-middle" />
                        </b-button>
                    </div>
                </b-form>
            </ValidationObserver>
        </b-container>
    </div>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import BFormPhone from '@/shared/components/BootstrapPhoneInput'

    export default ({
        components: {
            ValidationObserver,
            ValidationProvider,
            BFormPhone,
        },
        metaInfo: {
            title: 'Profiel details',
        },
        data() {
            return {
                isSubmited: false,
                user: {},
                genderSelect: [
                    { text: 'Maak een keuze', value: null },
                    { text: 'Man', value: 'male' },
                    { text: 'Vrouw', value: 'female' },
                    { text: 'Anders', value: 'other' },
                ],
            }
        },
        methods: {
            getUser: function() {
                this.$axios.get("https://api.tixgo.nl/customer")
                    .then( response => {
                        this.user = response.data.customer;
                    }
                );
            },
            saveForm: function() {
                this.isSubmited = true;
                this.$axios.put("https://api.tixgo.nl/customer/update", this.user)
                    .then( response => {
                        if (response.data.status == 'success') {
                            this.$toast.open({
                                message: 'Je wijzigingen zijn succesvol opgeslagen',
                                type: 'success',
                            });
                        } else {
                            this.$toast.open({
                                message: 'Er is iets fout gegaan. Probeer het opnieuw of neem contact op met de klantenservice voor verdere hulp.',
                                type: 'error',
                            });
                        }

                        this.isSubmited = false;
                    }
                );
            }
        },
        created() {
            this.getUser();
        }
    });
</script>